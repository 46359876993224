export const ROUTES_TRANSLATE = {
    routes: {
        myProfile: {
            path: "/my-profile",
            default: "",
            data: {
                breadcrumbName: "Meu perfil",
                metas: {}
            }

        },
        passwordReset: {
            path: "/forgot-my-password",
            default: "",
            data: {
                breadcrumbName: "Esqueci minha senha",
                metas: {}
            }
        },
        passwordEdit: {
            path: "/change-password",
            default: "",
            data: {
                breadcrumbName: "Alterar senha",
                metas: {}
            }
        },
        login: {
            path: "/login",
            empty: "",
            nextPath: "proximo-caminho",
            default: "",
            data: {
                breadcrumbName: "Entrar",
                metas: {}
            }
        },
        notFound: {
            path: "/not-found",
            anything: "**",
            default: "",
            data: {
                breadcrumbName: "Página não encontrada",
                metas: {}
            }
        },
        accounts: {
            list: "/accounts",
            default: "",
            data: {
                breadcrumbName: "Contas",
                metas: {}
            },
            create: {
                path: "new",
                data: {
                    breadcrumbName: "Nova conta",
                    metas: {}
                },
            },
            update: {
                path: ":id",
                data: {
                    breadcrumbName: "Editar conta",
                    metas: {}
                },
            }
        },
        users: {
            list: "/users",
            default: "",
            data: {
                breadcrumbName: "Usuários",
                metas: {}
            },
            create: {
                path: "new",
                data: {
                    breadcrumbName: "Novo usuário",
                    metas: {}
                },
            },
            update: {
                path: ":id",
                data: {
                    breadcrumbName: "Editar administrador",
                    metas: {}
                },
            },
        },
        repPAccesses: {
            list: "/rep-p-accesses",
            default: "",
            data: {
                breadcrumbName: "Conf. acessos",
                metas: {}
            },
            create: {
                path: "new",
                data: {
                    breadcrumbName: "Novo conf. acessos",
                    metas: {}
                },
            },
            update: {
                path: ":id",
                data: {
                    breadcrumbName: "Editar conf. acessos",
                    metas: {}
                },
            },
        },
        corporateTvVersions: {
            list: "/corporate-tv-versions",
            default: "",
            data: {
                breadcrumbName: "TV corporativa - Versões",
                metas: {}
            },
            create: {
                path: "new",
                data: {
                    breadcrumbName: "Nova TV corporativa - Versões",
                    metas: {}
                },
            },
            update: {
                path: ":id",
                data: {
                    breadcrumbName: "Editar TV corporativa - Versões",
                    metas: {}
                },
            },
        },
        nicknames: {
            list: "/nicknames",
            default: "",
            data: {
                breadcrumbName: "Apelidos",
                metas: {}
            },
            create: {
                path: "new",
                data: {
                    breadcrumbName: "Novo apelido",
                    metas: {}
                },
            },
            update: {
                path: ":id",
                data: {
                    breadcrumbName: "Editar apelido",
                    metas: {}
                },
            },

        },
        headquarters: {
            list: "/headquarters",
            default: "",
            data: {
                breadcrumbName: "Unidades",
                metas: {}
            },
            update: {
                path: ":id",
                data: {
                    breadcrumbName: "Editar unidade",
                    metas: {}
                },
            },

        },
        mobileVersions: {
            list: "/mobile-versions",
            default: "",
            data: {
                breadcrumbName: "Mobiles - versões",
                metas: {}
            },
            create: {
                path: "new",
                data: {
                    breadcrumbName: "Novo mobiles - versões",
                    metas: {}
                },
            },
            update: {
                path: ":id",
                data: {
                    breadcrumbName: "Editar mobiles - versões",
                    metas: {}
                },
            },

        },
        androidSticks: {
            list: "/android-sticks",
            default: "",
            data: {
                breadcrumbName: "TV corporativa - Aparelhos",
                metas: {}
            },
            create: {
                path: "new",
                data: {
                    breadcrumbName: "Nova TV corporativa - Aparelhos",
                    metas: {}
                },
            },
            update: {
                path: ":id",
                data: {
                    breadcrumbName: "Editar TV corporativa - Aparelhos",
                    metas: {}
                },
            },

        },
        feedbacks: {
          list: "/feedbacks",
            data: {
                breadcrumbName: "Feedbacks",
                metas: {}
            },
        }



    },
};
