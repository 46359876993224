import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { TranslateService } from "@ngx-translate/core";
import { Translate } from 'src/app/shared/helpers/translate';

import { RoutesGuard } from "./core/guards/routes/routes.guard";
import { TRANSLATIONS } from "./languages/pt-BR";


Translate.setInitialTranslations(TRANSLATIONS);

const LOGIN_DATA = {breadcrumbName: 'routes.login.data.breadcrumbName', metas: 'routes.login.data.metas'};
const NOT_FOUND_DATA = {breadcrumbName: 'routes.notFound.data.breadcrumbName', metas: 'routes.notFound.data.metas'};

const ROUTES = [
    {
        path: 'routes.feedbacks.list',
        loadChildren: () => import('./modules/feedbacks/feedbacks.module').then(module =>
            module.FeedbacksModule),
        canActivate: [RoutesGuard],
        data: {
            breadcrumbName: 'routes.feedbacks.data.breadcrumbName',
            metas: 'routes.feedbacks.data.metas'
        }
    },
    {
        path: 'routes.corporateTvVersions.list',
        loadChildren: () => import('./modules/corporate_tv_versions/corporate-tv-versions.module').then(module =>
            module.CorporateTvVersionsModule),
        canActivate: [RoutesGuard],
        data: {
            breadcrumbName: 'routes.corporateTvVersions.data.breadcrumbName',
            metas: 'routes.corporateTvVersions.data.metas'
        }
    },

    //REP-P
    {
        path: 'routes.repPAccesses.list',
        loadChildren: () => import('./modules/rep-p-accesses/rep-p-accesses.module').then(module => module.RepPAccessesModule),
        canActivate: [RoutesGuard],
        data: {
            breadcrumbName: 'routes.repPAccesses.data.breadcrumbName',
            metas: 'routes.repPAccesses.data.metas'
        }
    },

    //Headquarters
    {
        path: 'routes.headquarters.list',
        loadChildren: () => import('./modules/headquarters/headquarters.module').then(module => module.HeadquartersModule),
        canActivate: [RoutesGuard],
        data: {
            breadcrumbName: 'routes.headquarters.data.breadcrumbName',
            metas: 'routes.headquarters.data.metas'
        }
    },

    //Nicknames
    {
        path: 'routes.nicknames.list',
        loadChildren: () => import('./modules/accounts-nicknames/nicknames.module').then(module => module.NicknamesModule),
        canActivate: [RoutesGuard],
        data: {
            breadcrumbName: 'routes.nicknames.data.breadcrumbName',
            metas: 'routes.nicknames.data.metas'
        }
    },

    //Mobile Versions
    {
        path: 'routes.mobileVersions.list',
        loadChildren: () => import('./modules/mobile-versions/mobile-versions.module').then(module => module.MobileVersionsModule),
        canActivate: [RoutesGuard],
        data: {
            breadcrumbName: 'routes.mobileVersions.data.breadcrumbName',
            metas: 'routes.mobileVersions.data.metas'
        }
    },


    //android Sticks
    {
        path: 'routes.androidSticks.list',
        loadChildren: () => import('./modules/android-sticks/android-sticks.module').then(module => module.AndroidSticksModule),
        canActivate: [RoutesGuard],
        data: {
            breadcrumbName: 'routes.androidSticks.data.breadcrumbName',
            metas: 'routes.androidSticks.data.metas'
        }
    },

    // modules
    {
        path: 'routes.users.list',
        loadChildren: () => import('./modules/users/users.module').then(module => module.UsersModule),
        canActivate: [RoutesGuard],
        data: {
            breadcrumbName: 'routes.users.data.breadcrumbName',
            metas: 'routes.users.data.metas'
        }
    },

    // accounts
    {
        path: 'routes.accounts.list',
        loadChildren: () => import('./modules/accounts/accounts.module').then(module => module.AccountsModule),
        canActivate: [RoutesGuard],
        data: {
            breadcrumbName: 'routes.accounts.data.breadcrumbName',
            metas: 'routes.accounts.data.metas'
        }
    },

    // my profile
    {
        path: 'routes.myProfile.path',
        loadChildren: () => import('./modules/my-profile/my-profile.module').then(module => module.MyProfileModule),
        canActivate: [RoutesGuard],
        data: {
            breadcrumbName: 'routes.myProfile.data.breadcrumbName',
            metas: 'routes.myProfile.data.metas'
        }
    },
    // password screens
    {
        path: "routes.passwordReset.path",
        loadChildren: () =>
            import("./modules/password-reset/password-reset.module").then(
                (module) => module.PasswordResetModule
            ),
        canActivate: [RoutesGuard],
        data: {
            breadcrumbName: "routes.passwordReset.data.breadcrumbName",
            metas: "routes.passwordReset.data.metas",
        },
    },
    {
        path: "routes.passwordEdit.path",
        loadChildren: () =>
            import("./modules/password-edit/password-edit.module").then(
                (module) => module.PasswordEditModule
            ),
        canActivate: [RoutesGuard],
        data: {
            breadcrumbName: "routes.passwordEdit.data.breadcrumbName",
            metas: "routes.passwordEdit.data.metas",
        },
    },

    // login
    {
        path: 'routes.login.path',
        loadChildren: () => import('./modules/sign-in/sign-in.module').then(module => module.SignInModule),
        canActivate: [RoutesGuard],
        data: LOGIN_DATA
    },
    // no results
    {
        path: 'routes.notFound.path',
        loadChildren: () => import('./modules/not-found/not-found.module').then(module => module.NotFoundModule),
        canActivate: [RoutesGuard],
        data: NOT_FOUND_DATA
    },

    // empties
    {
        path: 'routes.login.empty',
        pathMatch: 'full',
        loadChildren: () => import('./modules/sign-in/sign-in.module').then(module => module.SignInModule),
        canActivate: [RoutesGuard],
        data: LOGIN_DATA
    },
    {
        path: 'routes.notFound.anything',
        pathMatch: 'full',
        loadChildren: () => import('./modules/not-found/not-found.module').then(module => module.NotFoundModule),
        canActivate: [RoutesGuard],
        data: NOT_FOUND_DATA
    },


];

@NgModule({
    imports: [
        RouterModule.forRoot(
            Translate.translateRoutes(ROUTES),
            {
                relativeLinkResolution: "corrected",
                anchorScrolling: "enabled",
                paramsInheritanceStrategy: "always"
            }
        )
    ],
    providers: [],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule {
    constructor(private translateService: TranslateService) {
        Translate.setService(this.translateService);
    }
}
