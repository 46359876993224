export const FEEDBACKS_TRANSLATE = {
    feedbacks: {
        list: {
            table: {
                columns: {
                    id: "ID",
                    comment: "Comentário",
                    platform: "Plataforma",
                    appVersion: "Versão do aplicativo",
                    accountName: "Conta",
                    osVersion: "Versão do dispositivo",
                    origin: "Origem/Tela"

                }
            }
        },
        params: {
            platforms:{
                computer: "Web",
                android: "Android",
                ios: "iOS",
            }
        }
    }

};
